<template>
    <div class="w-full">
        <validation-provider
            v-slot="{ errors }"
            :name="name"
            :rules="rules"
        >
            <b-field
                :label="label"
                :type="{
                    [InputContext.DEFAULT]: false,
                    [InputContext.NEGATIVE]: errors[0],
                    [InputContext.POSITIVE]: false,
                    [InputContext.INFO]: false,
                    [InputContext.WARN]: false,
                }"
                :message="errors"
            >
                <b-select
                    v-model="innerValue"
                    ref="select"
                    class="underline-select"
                    :name="name"
                    :size="size"
                    :placeholder="placeholder"
                    :loading="loading"
                    :expanded="expanded"
                    :disabled="disabled"
                    :required="required"
                    :rounded="rounded"
                >
                    <!-- accepts option or optgroup + option -->
                    <slot></slot>

                </b-select>
            </b-field>
        </validation-provider>
    </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

// theme context
const InputContext = {
    DEFAULT: 'is-default',
    WARN: 'is-warning',
    NEGATIVE: 'is-danger',
    POSITIVE: 'is-success',
    INFO: 'is-info',
    DISABLED: 'disabled',
};

const InputSize = {
    SMALL: 'is-small',
    DEFAULT: 'is-default',
    MEDIUM: 'is-medium',
    LARGE: 'is-large',
};

export default {
    name: 'Select',

    components: {
        ValidationProvider,
    },

    props: {
        // must be included in props
        value: {
            type: [Object, String, Number, Boolean],
            required: false,
            default: () => undefined,
        },
        vid: {
            type: String,
            required: false,
            default: () => undefined,
        },
        rules: {
            type: [Object, String, Date],
            default: () => '',
        },
        name: {
            type: String,
            required: false,
            default: () => '',
        },
        label: {
            type: String,
            required: false,
            default: () => '',
        },
        size: {
            type: String,
            required: false,
            default: () => InputSize.DEFAULT,
        },
        placeholder: {
            type: String,
            required: false,
            default: () => '',
        },
        loading: {
            type: Boolean,
            required: false,
            default: () => false,
        },
        expanded: {
            type: Boolean,
            required: false,
            default: () => true,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: () => false,
        },
        required: {
            type: Boolean,
            required: false,
            default: () => false,
        },
        rounded: {
            type: Boolean,
            required: false,
            default: () => false,
        },
    },

    data() {
        return {
            InputContext,
            innerValue: undefined,
        };
    },

    computed: {},

    watch: {
        // handles internal model changes
        innerValue(newVal) {
            return this.$emit('input', newVal);
        },
        // handles external model changes
        value(newVal) {
            this.innerValue = newVal;
        },
    },

    created() {
        if (this.value || ((this.value === 0) || (this.value === false))) {
            this.innerValue = this.value;
        }
    },

    methods: {},
};
</script>

<style>
.underline-select > span > select {
    border-left: 1px solid transparent !important;
    border-top: 1px solid transparent !important;
    border-right: 1px solid transparent !important;
    border-bottom: 1px solid black !important;
}
</style>
